import { Divider, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "storeContainer";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, IconButton, Popover, Skeleton, Tooltip } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { CloseIcon, ExpandLessIcon } from "components/common/icons";
import {
  IVBillIIntegrationDataType,
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillStoreIoDataType,
  IVBillVBillGetStoreItemQuery,
  getSdk,
} from "generated/sdk.vbill";
import { useWatch } from "react-hook-form";
import { useParams } from "react-router";
import { COLORS } from "themes/default";
import { VBillVendorDifferenceLine } from "../../common/VBillVendorDifferenceLine/VBillVendorDifferenceLine";
import { TAdditionalMappings } from "../../types";

const { VBillGetStoreItem } = getSdk(graphqlVBillClient);

const expandIconStyles = { fontSize: "14px !important" };

export const NewVendorDifference = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { organizationId } = useParams<{ organizationId: string }>();
  const invoiceVendorMapping = useMemo(
    () =>
      (vBillStore.settings.data?.vBill.additionalMappings.invoice ?? []).find(
        (additionalMapping) =>
          additionalMapping.showIn !==
            IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
          additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None &&
          additionalMapping.isContact,
      ),
    [vBillStore.settings.data?.vBill.additionalMappings.invoice],
  );
  const formInvoiceVendorMappingObserver: TAdditionalMappings[IVBillIIntegrationDataType] = useWatch({
    // name can't be dynamically
    name: invoiceVendorMapping?.storeDataType ?? "-1",
  });
  const [showVendorMappingSkeleton, setShowVendorMappingSkeleton] = useState(false);
  const [vendorMappingStoreItem, setVendorMappingStoreItem] = useState<
    IVBillVBillGetStoreItemQuery["getStoreItem"]["data"] | null
  >(null);
  const [moreDetailsPopoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null);
  const invoiceData = useMemo(() => vBillStore.invoice.data, [vBillStore.invoice.data]);
  const moreDetailsPopoverVisible = Boolean(moreDetailsPopoverAnchorEl);
  const moreDetailsPopoverId = moreDetailsPopoverVisible ? "difference-details" : undefined;
  const showVendorDifference = useMemo(
    () => !!(formInvoiceVendorMappingObserver && invoiceVendorMapping),
    [formInvoiceVendorMappingObserver, invoiceVendorMapping],
  );

  useEffect(() => {
    if (!formInvoiceVendorMappingObserver || !invoiceVendorMapping) {
      return;
    }

    setShowVendorMappingSkeleton(true);
    VBillGetStoreItem({
      organizationId,
      companyId: invoiceData?.companyId,
      type: invoiceVendorMapping.storeDataType as unknown as IVBillStoreIoDataType,
      key: formInvoiceVendorMappingObserver.key,
    }).then((resp) => {
      setVendorMappingStoreItem(resp.getStoreItem.data);
      setShowVendorMappingSkeleton(false);
    });
  }, [invoiceData?.companyId, formInvoiceVendorMappingObserver]);

  return (
    <>
      {showVendorDifference ? (
        <>
          <Divider sx={{ marginBottom: "10px" }} />

          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "flex-start", maxWidth: "200px" }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 500, color: COLORS.logoBlue }}>
              VENDOR DIFFERENCE <ErrorOutlineIcon sx={{ color: COLORS.textGray, fontSize: "14px" }} />
            </Typography>

            {showVendorMappingSkeleton ? (
              <Skeleton variant="rounded" sx={{ height: "20px", width: "100%" }} />
            ) : (
              vendorMappingStoreItem && (
                <>
                  <Button
                    onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
                    aria-describedby={moreDetailsPopoverId}
                    variant="text"
                    endIcon={
                      moreDetailsPopoverVisible ? (
                        <ExpandLessIcon sx={expandIconStyles} />
                      ) : (
                        <ExpandMoreIcon sx={expandIconStyles} />
                      )
                    }
                    sx={{ padding: "0", height: "auto", fontSize: "12px", "&:hover": { textDecoration: "underline" } }}
                  >
                    Details
                  </Button>
                  <Popover
                    id={moreDetailsPopoverId}
                    open={moreDetailsPopoverVisible}
                    anchorEl={moreDetailsPopoverAnchorEl}
                    onClose={() => setPopoverAnchorEl(null)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  >
                    <Box sx={{ padding: "20px 10px 10px", position: "relative" }}>
                      <Tooltip arrow title="Close">
                        <IconButton
                          onClick={() => setPopoverAnchorEl(null)}
                          sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1, padding: "2px" }}
                        >
                          <CloseIcon sx={{ fontSize: "14px" }} />
                        </IconButton>
                      </Tooltip>
                      <table>
                        <tbody>
                          <VBillVendorDifferenceLine
                            rowName="Name:"
                            integrationText={vendorMappingStoreItem.data?.contact?.name ?? "-"}
                            codingText={invoiceData?.senderName ?? ""}
                          />

                          <VBillVendorDifferenceLine
                            rowName="Address:"
                            integrationText={vendorMappingStoreItem.data?.contact?.address ?? "-"}
                            codingText={invoiceData?.senderAddress ?? ""}
                          />

                          <VBillVendorDifferenceLine
                            rowName="Email:"
                            integrationText={vendorMappingStoreItem.data?.contact?.email ?? "-"}
                            codingText={invoiceData?.senderEmail ?? ""}
                          />

                          <VBillVendorDifferenceLine
                            rowName="Phone:"
                            integrationText={vendorMappingStoreItem.data?.contact?.phone ?? "-"}
                            codingText={invoiceData?.senderPhone ?? ""}
                          />
                        </tbody>
                      </table>
                    </Box>
                  </Popover>
                </>
              )
            )}
          </Box>

          <Divider sx={{ margin: "10px 0" }} />
        </>
      ) : null}
    </>
  );
});
