import { Box, Button, Skeleton, Typography } from "@mui/material";
import { graphqlClient, graphqlVBillClient } from "common/graphqlClient";
import { formatCurrency } from "common/helpers/utils";
import { useVBillFormattedDate } from "components/pages/common/VBill/hooks";
import { getSdk as getCoreSdk, VCheckCheckQueryQuery } from "generated/sdk";
import {
  getSdk,
  IVBillPaymentPushStatus,
  IVBillVBillGetBillsQuery,
  IVBillVBillPaymentQuery,
} from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import {
  friendlyPaymentIntegrationStatusNames,
  friendlyPaymentPushStatusNames,
  getPaymentIntegrationStatusStyleColor,
  getPaymentPushStatusStyleColor,
} from "../utils";
import { BillLineItems } from "./BillLineItems/BillLineItems";
import { PaymentVCheckDialog } from "./PaymentVCheckDialog/PaymentVCheckDialog";

const { VCheckCheckQuery } = getCoreSdk(graphqlClient);
const { VBillPayment, VBillGetBills } = getSdk(graphqlVBillClient);

interface IPaymentDetailsProps {
  paymentId: number;
  onDrawerClose: () => void;
}

export const PaymentDetails = observer(function PaymentDetails({ paymentId, onDrawerClose }: IPaymentDetailsProps) {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [paymentData, setPaymentData] = useState<IVBillVBillPaymentQuery["payment"]>();
  const [paidBillsData, setPaidBillsData] = useState<IVBillVBillGetBillsQuery["getBills"]>();
  const [vCheckDialogVisible, setVCheckDialogVisible] = useState(false);
  const [vCheckData, setVCheckData] = useState<VCheckCheckQueryQuery["VCheckCheckQuery"]>();
  const organizationStore = useStore("OrganizationStore");

  useEffect(() => {
    if (paymentData && paymentData.vCheckId) {
      fetchVCheckCheckQuery(paymentData.vCheckId);
    }
  }, [paymentData]);

  const fetchVCheckCheckQuery = async (vCheckId: string) => {
    const resp = await VCheckCheckQuery({ id: vCheckId });

    setVCheckData(resp.VCheckCheckQuery);
  };

  useEffect(() => {
    fetchPaymentData(paymentId, organizationId);
  }, [paymentId, organizationId]);

  useEffect(() => {
    if (paymentData?.payableInvoices?.length) {
      fetchPaidBillsData(paymentData, organizationId);
    }
  }, [paymentData, organizationId]);

  const fetchPaymentData = async (paymentId: number, organizationId: string) => {
    const resp = await VBillPayment({ paymentId, organizationId });

    setPaymentData(resp.payment);
  };

  const fetchPaidBillsData = async (paymentData: IVBillVBillPaymentQuery["payment"], organizationId: string) => {
    const resp = await VBillGetBills({
      filters: {
        organizationId,
        ids: paymentData?.payableInvoices.map(({ invoiceId }) => invoiceId),
      },
      pagination: { page: 1, per_page: 100 },
    });

    setPaidBillsData(resp.getBills);
  };

  const paymentDataDetails = paymentData;
  const companyName = useMemo(
    () =>
      (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).find(
        ({ companyId }) => companyId === paymentDataDetails?.companyId,
      )?.name,
    [organizationStore.organizationVBillEnabledCompanies.data?.items, paymentDataDetails?.companyId],
  );
  const integrationStatus = useMemo(
    () =>
      paymentDataDetails?.paymentIntegrationStatus
        ? friendlyPaymentIntegrationStatusNames[paymentDataDetails.paymentIntegrationStatus]
        : null,
    [paymentDataDetails?.paymentIntegrationStatus],
  );
  const integrationStatusColor = useMemo(
    () => getPaymentIntegrationStatusStyleColor(paymentDataDetails?.paymentIntegrationStatus),
    [paymentDataDetails?.paymentIntegrationStatus],
  );
  const pushStatus = useMemo(
    () =>
      paymentDataDetails?.paymentPushStatus
        ? friendlyPaymentPushStatusNames[paymentDataDetails.paymentPushStatus]
        : null,
    [paymentDataDetails?.paymentPushStatus],
  );
  const pushStatusColor = useMemo(
    () => getPaymentPushStatusStyleColor(paymentDataDetails?.paymentPushStatus),
    [paymentDataDetails?.paymentPushStatus],
  );
  const formattedPaymentDate = useVBillFormattedDate(paymentDataDetails?.paymentDate);
  const amount = useMemo(
    () => formatCurrency(Number(paymentDataDetails?.paymentAmount ?? 0)),
    [paymentDataDetails?.paymentAmount],
  );

  return (
    <Box sx={{ minWidth: "660px" }}>
      <Box sx={{ paddingBottom: "20px" }}>
        {!paymentDataDetails ? (
          <Skeleton variant="rounded" sx={{ height: "41px" }} />
        ) : (
          <>
            <Typography fontSize="26px" fontWeight="600" marginBottom="10px" color={COLORS.logoBlue}>
              Payment #{paymentDataDetails.paymentId ?? ""}
            </Typography>
            <Typography>
              Status:{" "}
              {paymentDataDetails.paymentPushStatus === IVBillPaymentPushStatus.SubmittedOk ? (
                <Box component="span" sx={{ color: integrationStatusColor }}>
                  {integrationStatus}
                </Box>
              ) : (
                <Box component="span" sx={{ color: pushStatusColor }}>
                  {pushStatus}
                </Box>
              )}
            </Typography>
          </>
        )}
      </Box>

      {vCheckData && (
        <Box sx={{ paddingBottom: "20px" }}>
          <Typography sx={{ fontSize: 16, fontWeight: 500 }} marginBottom="10px" color={COLORS.logoBlue}>
            VCHECK: id: {paymentDataDetails?.vCheckId ?? "-"}, nr: {paymentDataDetails?.vCheckNumber ?? "-"}
          </Typography>
          <Typography>Status: {vCheckData?.ledger_check?.status?.state} </Typography>

          {paymentDataDetails?.vCheckId && (
            <Button
              onClick={() => setVCheckDialogVisible(true)}
              sx={{
                padding: 0,
                minWidth: "auto",
                fontSize: "14px",
                fontWeight: "500",
                color: COLORS.newSecondaryColor,
                height: "24px",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Details
            </Button>
          )}
        </Box>
      )}

      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            padding: "20px 20px 20px 0",
            borderRight: 1,
            borderColor: COLORS.lightestGrey,
            flex: 1,
          }}
        >
          <Typography sx={{ fontSize: 16, marginBottom: "15px", fontWeight: 500 }} color={COLORS.logoBlue}>
            FROM:
          </Typography>
          {!paymentDataDetails ? (
            <Skeleton variant="rounded" sx={{ height: "122px" }} />
          ) : (
            <>
              <Box sx={{ marginBottom: "10px" }}>
                <Typography>Company: </Typography>
                <Typography>
                  <strong>{companyName}</strong>
                </Typography>
              </Box>

              <Box>
                <Typography>GL Account: </Typography>
                <Typography>
                  <strong>
                    {paymentDataDetails.paymentFromGlAccount.label} - {paymentDataDetails.paymentFromGlAccount.label2}
                  </strong>
                </Typography>
              </Box>
            </>
          )}
        </Box>

        <Box
          sx={{
            padding: "20px",
            borderRight: 1,
            borderColor: COLORS.lightestGrey,
            flex: 1,
          }}
        >
          <Typography sx={{ fontSize: 16, marginBottom: "15px", fontWeight: 500 }} color={COLORS.logoBlue}>
            TO:
          </Typography>
          {!paymentDataDetails ? (
            <Skeleton variant="rounded" sx={{ height: "122px" }} />
          ) : (
            <>
              <Box>
                <Typography>Vendor Account: </Typography>
                <Typography>
                  <strong>
                    {paymentDataDetails.paymentToVendorAccount.label} -{" "}
                    {paymentDataDetails.paymentToVendorAccount.label2}
                  </strong>
                </Typography>
              </Box>
            </>
          )}
        </Box>

        <Box
          sx={{
            padding: "20px 0 20px 20px",
            flex: 1,
          }}
        >
          <Typography sx={{ fontSize: 16, marginBottom: "15px", fontWeight: 500 }} color={COLORS.logoBlue}>
            BY:
          </Typography>
          {!paymentDataDetails ? (
            <Skeleton variant="rounded" sx={{ height: "122px" }} />
          ) : (
            <>
              <Box sx={{ marginBottom: "10px" }}>
                <Typography>User: </Typography>
                <Typography>
                  <strong>
                    {paymentDataDetails.submittedByUserAccount?.userName} -{" "}
                    {paymentDataDetails.submittedByUserAccount?.userEmail}
                  </strong>
                </Typography>
              </Box>

              <Box sx={{ marginBottom: "10px" }}>
                <Typography>Date: </Typography>
                <Typography>
                  <strong>{formattedPaymentDate}</strong>
                </Typography>
              </Box>

              <Box>
                <Typography>Amount: </Typography>
                <Typography>
                  <strong>{amount}</strong>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>

      {!paymentDataDetails ? (
        <Skeleton variant="rounded" sx={{ height: "150px", marginTop: "20px" }} />
      ) : (
        <BillLineItems payment={paymentDataDetails} paidBillsItems={paidBillsData?.items} />
      )}

      {vCheckDialogVisible && vCheckData && (
        <PaymentVCheckDialog
          ledgerCheck={vCheckData.ledger_check}
          onCloseDialog={() => setVCheckDialogVisible(false)}
        />
      )}
    </Box>
  );
});
