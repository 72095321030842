import { FormControl, FormHelperText, Skeleton } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import {
  IVBillInvoiceIncomingStatus,
  IVBillKFileOcrState,
  IVBillVBillFilesOcrQuery,
  getSdk,
} from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { FilesSelector, TVBillFileInfo } from "../../common/FilesSelector/FilesSelector";
import { useCurrentVBillRoute } from "../../hooks";
import { TVBillFormFields } from "../../types";
import {
  getVBillFormPrimaryValuesByFileId,
  getVBillFormPrimaryValuesByInvoice,
  lineItemToFormLineFields,
} from "../../utils";
import { vBillChatPanels, vBillSidebarPanels } from "../SidebarTabs/SidebarTabs";
import { TabPanel } from "../SidebarTabs/TabPanel/TabPanel";
import { AttachmentsViewer } from "./AttachmentsViewer";

const { VBillFilesOcr } = getSdk(graphqlVBillClient);

interface IAttachmentsProps {
  activePannel: vBillSidebarPanels | vBillChatPanels;
}

export const Attachments = observer(({ activePannel }: IAttachmentsProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { isIncomingRoute } = useCurrentVBillRoute();
  const vBillStore = useStore("VBillStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const [imageFilesData, setImageFilesData] = useState<IVBillVBillFilesOcrQuery["files"]>();
  const [selectedPageInFile, setSelectedPageInFile] = useState<number>(1);
  const [isFetchFilesLoading, setIsFetchFilesLoading] = useState(false);
  const {
    field: { value: formFieldValue = null },
    fieldState: { error: formFieldError },
  } = useController({
    name: "mainFile",
    rules: { required: true },
  });
  const formMethods = useFormContext<TVBillFormFields>();
  const [selectedFile, setSelectedFile] = useState<TVBillFileInfo>();

  useEffect(() => {
    setSelectedFile(
      invoiceData?.files?.find(({ id }) => id === (isIncomingRoute ? formFieldValue?.value : invoiceData.mainFile?.id)),
    );
  }, [invoiceData, formFieldValue?.value, invoiceData?.mainFile?.id, invoiceData?.status]);

  useEffect(() => {
    if (
      !selectedFile ||
      selectedFile?.fileIsEncrypted ||
      selectedFile?.fileOcrState === IVBillKFileOcrState.OcrError ||
      selectedFile?.fileOcrState === IVBillKFileOcrState.Unknown ||
      selectedFile?.fileOcrState === IVBillKFileOcrState.OcrStartTriggered ||
      Boolean(selectedFile?.movedToInvoice?.id)
    ) {
      return;
    }

    setIsFetchFilesLoading(true);
    VBillFilesOcr({
      filters: {
        coreOrgId: organizationId,
        invoiceId: invoiceData?.id ?? 0,
        attachmentId: selectedFile?.id ?? 0,
      },
      pagination: { page: selectedPageInFile },
    }).then(({ files }) => {
      setImageFilesData(files);
      setIsFetchFilesLoading(false);
    });
  }, [selectedFile, selectedPageInFile, organizationId, invoiceData?.id]);

  const handleChangeFileBtnClick = (file: TVBillFileInfo) => {
    if (file.movedToInvoice?.id) {
      return;
    }

    setSelectedPageInFile(1);
    setSelectedFile(file);

    if (isIncomingRoute) {
      if (invoiceData?.status === IVBillInvoiceIncomingStatus.Queued) {
        const vBillFormPrimaryValues = getVBillFormPrimaryValuesByFileId(invoiceData, file.id);

        formMethods.reset(vBillFormPrimaryValues);
      } else {
        if (invoiceData?.mainFile?.id === file.id) {
          const vBillFormPrimaryValues = getVBillFormPrimaryValuesByInvoice(invoiceData);
          const formValues = {
            ...vBillFormPrimaryValues,
            lineItems: invoiceData.hasLineItems
              ? (invoiceData?.lineItems ?? []).map((lineItem) => lineItemToFormLineFields(lineItem))
              : [],
          };

          formMethods.reset(formValues);
        } else {
          const vBillFormPrimaryValues = getVBillFormPrimaryValuesByFileId(invoiceData, file.id);

          formMethods.reset(vBillFormPrimaryValues);
        }
      }
    }
  };

  if (!invoiceData?.files?.length) {
    return null;
  }

  return (
    <TabPanel activePannel={activePannel} panelName={vBillSidebarPanels.ATTACHMENTS}>
      <FilesSelector selectedFile={selectedFile!} onChangeFileBtnClick={handleChangeFileBtnClick} />

      {formFieldError && (
        <FormControl error>
          <FormHelperText sx={{ margin: "10px 0", lineHeight: "15px" }}>Main file is required.</FormHelperText>
        </FormControl>
      )}

      {isFetchFilesLoading ? (
        <Skeleton variant="rectangular" sx={{ width: "100%", height: "500px" }} />
      ) : (
        <AttachmentsViewer imageFilesData={imageFilesData} onChangePageBtnClick={setSelectedPageInFile} />
      )}
    </TabPanel>
  );
});
