import { SearchIcon } from "@heroicons/react/solid";
import EditIcon from "@mui/icons-material/Edit";
import { Backdrop, IconButton, InputAdornment, Menu, MenuItem, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/system/Unstable_Grid";
import { VBillCopyTextToClipboard } from "components/pages/common/VBill/VBillCopyTextToClipboard/VBillCopyTextToClipboard";
import { SettingsIcon } from "icons/svg/SettingsIcon";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useStore } from "storeContainer";
import EditOrganizationNameModal from "./EditOrganizationNameModal";

export const Header: React.FunctionComponent<{
  depositAccounts: true | undefined;
  selectedContextTab: string;
  searchKeywords: string;
  handleChange(value: string): void;
  handleToggleChange(_: unknown, newValue: string): void;
  setCurrentPageState?: Function;
  depositAccountsOnly?: boolean;
}> = observer(
  ({
    searchKeywords,
    handleChange,
    depositAccounts,
    selectedContextTab,
    handleToggleChange,
    setCurrentPageState,
    depositAccountsOnly,
  }) => {
    const ctxStore = useStore("ContextStore");
    const [orgNameModalOpen, setOrgNameModalOpen] = useState<boolean>(false);
    const sessionStore = useStore("SessionStore");
    const organizationStore = useStore("OrganizationStore");
    const { organizationDefaultDashboard } = organizationStore;
    const { organizationId } = useParams<{ organizationId: string }>();
    const { acl } = sessionStore;
    const history = useHistory();
    const handleOpenEditOrgNameModal = () => {
      setOrgNameModalOpen(true);
    };
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography variant={"h1"} sx={{ fontSize: 36 }}>
              {depositAccounts ? "Bank Accounts" : ctxStore.selectedOrganization?.name}
            </Typography>

            {acl.canManageOrganization && organizationId && (
              <Tooltip title="Edit organization name" arrow>
                <IconButton onClick={handleOpenEditOrgNameModal}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          {acl.canManageUsers && (
            <Box>
              <Tooltip title="Edit users & permisions" arrow>
                <IconButton onClick={handleClick}>
                  <SettingsIcon color="inherit" />
                </IconButton>
              </Tooltip>

              <Menu
                id="SettingsMenu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                MenuListProps={{
                  "aria-labelledby": "SettingsMenu-button",
                }}
                slots={{ backdrop: Backdrop }}
              >
                <MenuItem
                  onClick={() => {
                    history.push(`/org/${organizationId}/users2`);
                    handleClose();
                  }}
                >
                  Users & Permissions
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>

        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
          {organizationDefaultDashboard.data?.vbill_email && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "10px",
              }}
            >
              <Typography>
                Bills email: <strong>{organizationDefaultDashboard.data.vbill_email}</strong>
              </Typography>

              <VBillCopyTextToClipboard textToCopy={organizationDefaultDashboard.data.vbill_email} />
            </Box>
          )}
        </Box>
        <EditOrganizationNameModal setOpen={setOrgNameModalOpen} open={orgNameModalOpen} />
        <Grid display="flex" justifyContent="space-between">
          <Box sx={{ display: "flex", gap: "15px" }}>
            <Box>
              <TextField
                placeholder="Search"
                onChange={(event) => {
                  if (setCurrentPageState) setCurrentPageState(1);
                  handleChange(event.target.value);
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    // Do code here
                    ev.preventDefault();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Tabs
              value={selectedContextTab}
              onChange={handleToggleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              {!depositAccountsOnly && <Tab key="all_accounts" value={"all_accounts"} label="All Accounts" />}
              {!depositAccountsOnly && (
                <Tab key="only_transactions" value={"only_transactions"} label="Only with Transactions" />
              )}
              <Tab key="deposit_accounts" value={"deposit_accounts"} label="Deposit Accounts" />
            </Tabs>
          </Box>
        </Grid>
      </Box>
    );
  },
);
