import CommentIcon from "@mui/icons-material/Comment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PaymentIcon from "@mui/icons-material/Payment";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import { getApprovalFlags } from "components/common/Approval/getApprovalFlags";
import { ApprovalIcon, AttachFileIcon, HistoryIcon } from "components/common/icons";
import { IActionlogObjectType } from "generated/graphql.actionlog";
import { IVBillInvoicePushStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { SyntheticEvent, forwardRef, useMemo } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { ActivityLog } from "../../../../common/ActivityLog/ActivityLog";
import { Approval } from "../Approval/Approval";
import { Attachments } from "../Attachments/Attachments";
import { BillPayments } from "../BillPayments/BillPayments";
import { ChatList } from "../ChatList/ChatList";
import { EmailInbox } from "../EmailInbox/EmailInbox";
import { TabBtn, a11yProps } from "./TabBtn/TabBtn";
import { TabPanel } from "./TabPanel/TabPanel";

export const vBillSidebarPanelQSKey = "sidebar-tab-panel";
export enum vBillSidebarPanels {
  "ATTACHMENTS" = "attachments",
  "CHAT" = "chat",
  "ACTIVITY_LOG" = "activity_log",
  "BILL_PAYMENTS" = "bill_payments",
  "APPROVAL" = "approval",
  "EMAIL" = "email",
}
export const vBillChatPanelQSKey = "chat_panel";
export enum vBillChatPanels {
  "SR_CHAT" = "sr_chat",
  "SUPPORT_CHAT" = "support_chat",
  "RS_CHAT" = "rs_chat",
}

export interface ISidebarTabsRef {
  tabsUpdateIndicator: () => void;
}

export const SidebarTabs = observer(
  forwardRef<ISidebarTabsRef>((_, ref) => {
    const vBillStore = useStore("VBillStore");
    const sessionStore = useStore("SessionStore");
    const organizationStore = useStore("OrganizationStore");
    const { invoice } = vBillStore;
    const invoiceData = useMemo(() => invoice?.data, [invoice?.data]);
    const { organizationId } = useParams<{ organizationId: string }>();
    const [sidebarPanel, setSidebarPanel] = useQueryParam<vBillSidebarPanels>(
      vBillSidebarPanelQSKey,
      withDefault<any, vBillSidebarPanels>(
        StringParam,
        invoiceData?.payments?.length
          ? vBillSidebarPanels.BILL_PAYMENTS
          : invoiceData?.files?.length
            ? vBillSidebarPanels.ATTACHMENTS
            : vBillSidebarPanels.ACTIVITY_LOG,
      ),
      { removeDefaultsFromUrl: true },
    );
    const [, setChatPanel] = useQueryParam<vBillChatPanels>(
      vBillChatPanelQSKey,
      withDefault<any, vBillChatPanels>(StringParam, vBillChatPanels.SUPPORT_CHAT),
      { removeDefaultsFromUrl: true },
    );
    const activityLogFilters = useMemo(
      () => ({ type: IActionlogObjectType.Bill, key: `${invoiceData?.id}`, organizationId }),
      [invoiceData?.id, organizationId],
    );
    const showApprovalTab = useMemo(
      () =>
        invoiceData?.approvalStatus
          ? getApprovalFlags(invoiceData?.approvalStatus, sessionStore.selectedOrganizationUserId!).showApprovalWidget
          : false,
      [invoiceData?.approvalStatus, sessionStore.selectedOrganizationUserId],
    );
    const tabBtns = useMemo(
      () => [
        ...(invoiceData?.pushStatus === IVBillInvoicePushStatus.Done &&
        !!invoiceData?.amounts &&
        !!organizationStore.organizationVBillPayLedgerEnabled
          ? [{ label: "Payments", icon: <PaymentIcon />, value: vBillSidebarPanels.BILL_PAYMENTS }]
          : []),
        { label: "Chat", icon: <CommentIcon />, value: vBillSidebarPanels.CHAT },
        { label: "Activity log", icon: <HistoryIcon />, value: vBillSidebarPanels.ACTIVITY_LOG },
        ...(invoiceData?.files?.length
          ? [{ label: "Attachments", icon: <AttachFileIcon />, value: vBillSidebarPanels.ATTACHMENTS }]
          : []),
        { label: "Email", icon: <MailOutlineIcon />, value: vBillSidebarPanels.EMAIL },
        ...(showApprovalTab ? [{ label: "Approval", icon: <ApprovalIcon />, value: vBillSidebarPanels.APPROVAL }] : []),
      ],
      [
        invoiceData?.pushStatus,
        !!invoiceData?.amounts,
        !!organizationStore.organizationVBillPayLedgerEnabled,
        invoiceData?.files?.length,
        showApprovalTab,
      ],
    );

    return (
      <Box
        sx={{
          // TODO: "HEADER HEIGHT"
          maxHeight: "calc(100vh - 91px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ paddingRight: "20px" }}>
          <Tabs
            sx={{
              borderBottom: `1px solid ${COLORS.lightestGrey}`,
              position: "sticky",
              top: "0",
              zIndex: 1000,
              backgroundColor: "#f9fbff",
              minHeight: "57px",
            }}
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            value={sidebarPanel}
            onChange={(_event: SyntheticEvent<Element, Event>, value: vBillSidebarPanels) => {
              if (value !== vBillSidebarPanels.CHAT) {
                setChatPanel(vBillChatPanels.SUPPORT_CHAT);
              }
              setSidebarPanel(value);
            }}
            aria-label="invoice tabs"
          >
            {tabBtns.map((tabBtn, index) => (
              <TabBtn
                icon={tabBtn.icon}
                iconPosition="start"
                key={index}
                label={tabBtn.label}
                {...a11yProps(index)}
                value={tabBtn.value}
              />
            ))}
          </Tabs>
        </Box>

        <Attachments activePannel={sidebarPanel} />

        <TabPanel activePannel={sidebarPanel} panelName={vBillSidebarPanels.ACTIVITY_LOG}>
          {invoiceData && <ActivityLog filters={activityLogFilters} reloadObserver={invoice} />}
        </TabPanel>

        <TabPanel activePannel={sidebarPanel} panelName={vBillSidebarPanels.CHAT} sx={{ paddingTop: 0 }}>
          {invoiceData && <ChatList ref={ref} />}
        </TabPanel>

        <EmailInbox activePannel={sidebarPanel} />

        <BillPayments activePannel={sidebarPanel} />

        <Approval activePannel={sidebarPanel} />
      </Box>
    );
  }),
);
