import crossFetch from "cross-fetch";
import { GraphQLClient } from "graphql-request";
import localForage from "localforage";
import hash from "object-hash";
import { appConfig } from "./appConfig";

export type GqlErrorQueue = {
  message: string;
  operationName?: string;
  variables?: { [key: string]: any };
  responseBody?: any;
};

(window as any).gqlErrorsQueue = [];

const jsonParseTry = <T = any>(str: string | undefined | null, defaultValue: T): T => {
  try {
    return str ? JSON.parse(str) : defaultValue;
  } catch (e) {}
  return defaultValue;
};

(window as any).cacheOn = +import.meta.env.VITE_APP_CACHE!;

(window as any).cacheClear = () => localForage.clear();

const cacheKey = (operationName: string, variables: any) => `${operationName}-${hash.sha1({ variables })}`;
const cacheGet = async (operationName: string, variables: any) =>
  (window as any).cacheOn
    ? jsonParseTry(await localForage.getItem(cacheKey(operationName, variables)), undefined)
    : undefined;
const cacheSet = (operationName: string, variables: any, value: any) =>
  (window as any).cacheOn && localForage.setItem(cacheKey(operationName, variables), JSON.stringify(value));

const customFetch = async (input: RequestInfo | URL, init?: RequestInit | undefined) => {
  // fetch: (input: any, init?: any) => {
  const ts = +new Date();
  const requestBody = jsonParseTry<{ operationName?: string; query?: string; variables?: any }>(
    init?.body?.toString(),
    {},
  );
  const { operationName, query, variables } = requestBody;

  const cached = operationName ? await cacheGet(operationName, variables) : undefined;
  if (cached) {
    const cachedResponse = new Response(JSON.stringify(cached), {
      statusText: "OK",
      status: 200,
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    const cachedResponseProxy = new Proxy(cachedResponse, {
      get(target, prop, _receiver) {
        if (prop === "headers") return new Headers({ "Content-Type": "application/json" });
        if (prop === "json") return () => cached;
        return (target as any)[prop];
      },
    });
    // (cachedResponse as any).headers = new Headers({ "Content-Type": "application/json" });
    return cachedResponseProxy;
  }

  const newInput = operationName && import.meta.env.MODE === "development" ? `${input}/${operationName}` : `${input}`; // TODO:
  const result = crossFetch(newInput, {
    ...init,
    headers: {
      ...init?.headers,
      ...(operationName && { "X-Gql-Op": operationName }),
    },
  });
  result
    .then((response) => {
      const took = +new Date() - ts;

      if (operationName)
        response
          .clone()
          .json()
          .then((responseBody) => {
            const errors = responseBody?.errors;

            if (errors) {
              console.error("GQL ERR", operationName, took, errors?.debugMessage || errors?.message, {
                responseBody,
                query,
                variables,
              });

              window.gqlErrorsQueue?.push({
                operationName,
                message: errors?.debugMessage || errors?.message,
                variables,
                responseBody,
              });

              throw errors;
            } else {
              cacheSet(operationName, variables, responseBody);
            }
          });

      return response;
    })
    .catch((err) => {
      const took = +new Date() - ts;
      console.error("GQL ERR", operationName, took, err, { query, variables });
      window.gqlErrorsQueue?.push({
        operationName,
        message: err,
        variables,
      });
      return Promise.reject(err);
    });

  return result;
};

const graphqlClient = new GraphQLClient(appConfig.VITE_APP_GRAPHQL_ENDPOINT, {
  fetch: customFetch,
});

const graphqlCheckBatchingClient = new GraphQLClient(appConfig.VITE_APP_GRAPHQL_CHECK_BATCHING_ENDPOINT, {
  fetch: customFetch,
});

const graphqlVBillClient = new GraphQLClient(appConfig.VITE_APP_GRAPHQL_VBILL_ENDPOINT, {
  fetch: customFetch,
});

const graphqlActionLogClient = new GraphQLClient(appConfig.VITE_APP_GRAPHQL_ACTION_LOG_ENDPOINT, {
  fetch: customFetch,
});

const graphqlChatClient = new GraphQLClient(appConfig.VITE_APP_GRAPHQL_CHAT_ENDPOINT, {
  fetch: customFetch,
});

export { graphqlActionLogClient, graphqlChatClient, graphqlCheckBatchingClient, graphqlClient, graphqlVBillClient };
