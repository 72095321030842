import MoveUpIcon from "@mui/icons-material/MoveUp";
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import { EditBtnIcon, MoreHorizIcon } from "components/common/icons";
import { IVBillInvoiceIncomingStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { useCurrentVBillRoute } from "../../../hooks";

export const ToolbarActionsMenu = observer(() => {
  const history = useHistory();
  const { organizationId, vbillId } = useParams<{ organizationId: string; vbillId: string }>();
  const { isSummaryRoute } = useCurrentVBillRoute();
  const vBillStore = useStore("VBillStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => setMenuAnchorEl(null);

  const handleEditInvoiceBtnClick = () => {
    const url = `/org/${organizationId}/vbill/${vbillId}/incoming`;

    handleClose();
    history.push(url);
  };

  const handleEditMappingBtnClick = () => {
    const url = `/org/${organizationId}/vbill/${vbillId}/new`;

    handleClose();
    history.push(url);
  };

  if (
    invoiceData?.status !== IVBillInvoiceIncomingStatus.New &&
    invoiceData?.status !== IVBillInvoiceIncomingStatus.Mapped
  ) {
    return null;
  }

  const isVisible = Boolean(menuAnchorEl);

  return (
    <Box>
      <Tooltip title="Menu" arrow>
        <IconButton
          aria-label="more"
          id="toolbarActions-button"
          aria-controls={isVisible ? "long-menu" : undefined}
          aria-expanded={isVisible ? "true" : undefined}
          aria-haspopup="true"
          onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        >
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="toolbarActions-menu"
        anchorEl={menuAnchorEl}
        open={isVisible}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "toolbarActions-button",
        }}
      >
        <MenuItem onClick={handleEditInvoiceBtnClick}>
          <ListItemIcon>
            <EditBtnIcon sx={{ fontSize: "16px", color: COLORS.mediumGrey }} />
          </ListItemIcon>
          <ListItemText>Start Coding</ListItemText>
        </MenuItem>

        {isSummaryRoute && (
          <MenuItem onClick={handleEditMappingBtnClick}>
            <ListItemIcon>
              <MoveUpIcon sx={{ fontSize: "16px", color: COLORS.mediumGrey }} />
            </ListItemIcon>
            <ListItemText>Code Bill</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
});
