import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Box, Tooltip, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { formatCurrency } from "common/helpers/utils";
import { Spinner } from "components/common/Spinner/Spinner";
import { getFiltersConfig, LedgerFilterInputType } from "components/Filter/types";
import { LedgerCheck } from "generated/sdk";
import { definitelyFilter } from "generated/utils";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { COLORS } from "themes/default";
import { formatDateToDesign, formatWords, getAdditionalFields, headerCells } from "../utils";
import styles from "../VChecks.module.scss";
import CheckAction from "./CheckAction";
import { CheckOptions } from "./CheckOptions";
import { IVChecksTableProps } from "./types";
import { VCheckToChatDetails } from "./VCheckToChatDetails/VCheckToChatDetails";

export const highlightMatchingText = (text: string, highlightTerm?: string) => {
  if (!highlightTerm) {
    return text;
  }
  const escapedHighlightTerm = highlightTerm?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(`(${escapedHighlightTerm})`, "gi");
  const parts = text?.split(regex);

  return parts?.map((part, i) =>
    regex.test(part) ? (
      <strong key={i} style={{ fontWeight: "bold" }}>
        {part}
      </strong>
    ) : (
      part
    ),
  );
};

const VChecksTable = ({
  checks,
  handleAction,
  handleVCheck,
  selectedRowIds,
  setSelectedRowIds,
  selectedRowIdsShouldIncludeRole,
  filters,
  setFilters,
  reload,
  setDeselected,
  isLoading = false,
  rowsPerPage,
  hasInProgressOnboarding = false,
}: IVChecksTableProps) => {
  type Order = "asc" | "desc";
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = React.useState<Order>("asc");
  const [dateContext, setDateContext] = useState<string>("");
  const { path } = useRouteMatch();
  const { selectedQuickFilter } = useParams<{ selectedQuickFilter: string }>();
  const matchPayments = !!useRouteMatch("/org/:organizationId/vChecks/payments/:selectedQuickFilter");

  const formConfig = getFiltersConfig(path, selectedQuickFilter);
  const dateFormConfig = formConfig?.[LedgerFilterInputType.AdvancedDateFilter];
  const defaultDateContext = dateFormConfig?.contextOptions.find((context) => context.default)?.value ?? "";

  const formatSelectedRowId = (check: LedgerCheck) =>
    `${check.id}${selectedRowIdsShouldIncludeRole ? `-${check.role}` : ""}`;

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.target.checked) {
      const newSelectedRows = checks?.map(formatSelectedRowId);

      setSelectedRowIds(newSelectedRows);
    } else {
      setSelectedRowIds([]);
    }
  };

  const handleSorting = (name: string) => {
    setSelectedRowIds([]);
    const isAsc = orderBy === name && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(name);
    const sorting = `${name}_sort`;
    const prevFilters = {
      ...filters,
      date_sort: undefined,
      number_sort: undefined,
      status_sort: undefined,
      amount_sort: undefined,
    };
    setFilters({ ...prevFilters, [sorting]: isAsc ? "desc" : "asc" });
  };

  const handleRowClick = (_event: React.MouseEvent<unknown>, id: string, amount: number) => {
    setSelectedRowIds((prevSelectedRowIds: string[]) => {
      const selectedIndex = prevSelectedRowIds.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat([...prevSelectedRowIds], id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat([...prevSelectedRowIds].slice(1));
      } else if (selectedIndex === [...prevSelectedRowIds].length - 1) {
        newSelected = newSelected.concat([...prevSelectedRowIds].slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          [...prevSelectedRowIds].slice(0, selectedIndex),
          [...prevSelectedRowIds].slice(selectedIndex + 1),
        );
      }
      return newSelected;
    });
  };

  useEffect(() => {
    const sortKeys = ["date_sort", "amount_sort", "number_sort", "status_sort", "created_at_sort"];
    const activeSortKey = sortKeys.find((key) => filters?.[key] !== undefined);
    const activeSortName = activeSortKey?.split("_")[0];
    if (activeSortKey && activeSortName) {
      setOrder(filters[activeSortKey]);
      setOrderBy(activeSortName);
    }
    if (dateFormConfig) {
      const dateContext = filters?.date?.context ?? defaultDateContext;
      setDateContext(dateFormConfig.contextOptions.find((context) => dateContext === context.value)?.label ?? "");
    }
  }, [filters, dateFormConfig, defaultDateContext]);

  return (
    <TableContainer
      style={{ overflow: "initial" }}
      className={styles.tableContainer}
      sx={{ paddingBottom: rowsPerPage > 10 ? "100px" : "none" }}
    >
      <Table stickyHeader>
        <TableHead
          sx={{
            position: "sticky",
            top: "72px",
            zIndex: 2,
          }}
        >
          {/* {showFilterDiffWarning && (
            <TableRow>
              <TableCell colSpan={10}>You have shared filters that have not been applied due to permissions</TableCell>
            </TableRow>
          )} */}
          <TableRow>
            <TableCell sx={{ width: "10px" }} align={"left"} variant="head">
              <Checkbox
                indeterminate={selectedRowIds.length > 0 && selectedRowIds.length < definitelyFilter(checks).length}
                checked={
                  definitelyFilter(checks).length > 0 && selectedRowIds.length === definitelyFilter(checks).length
                }
                disableRipple
                onChange={handleSelectAll}
                inputProps={{
                  "aria-label": "Select all checks",
                }}
              />
            </TableCell>
            {headerCells.map((headerCell) =>
              headerCell.sortable ? (
                <TableCell key={headerCell.id} sortDirection={orderBy === headerCell.id ? order : false} variant="head">
                  <TableSortLabel
                    sx={{ whiteSpace: "nowrap" }}
                    active={orderBy === headerCell.id}
                    direction={orderBy === headerCell.id ? order : "asc"}
                    IconComponent={KeyboardArrowDownIcon}
                    onClick={() => handleSorting(headerCell.id)}
                  >
                    {headerCell.id === "date" ? dateContext : headerCell.label}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell key={headerCell.id} variant="head">
                  {headerCell.label}
                </TableCell>
              ),
            )}
          </TableRow>
        </TableHead>
        {isLoading ? (
          <TableBody>
            <TableRow sx={{ position: "relative", height: "100px" }}>
              <TableCell sx={{ position: "absolute", left: "50%", top: "50%", border: "none" }} align="center">
                <Spinner />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : checks?.length === 0 ? (
          <TableBody>
            <TableRow sx={{ position: "relative", height: "100px" }}>
              <TableCell sx={{ position: "absolute", top: "10%", border: "none" }} align="center">
                <Typography>No results found</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {definitelyFilter(checks).map((check) => {
              const isSelected = (id: string, role: string) =>
                selectedRowIds.indexOf(selectedRowIdsShouldIncludeRole ? `${id}-${role}` : id) !== -1;
              const isItemSelected = isSelected(check.id, check.role!);

              return (
                <TableRow
                  hover
                  selected={isItemSelected}
                  key={`${check.id}-${check.role}`}
                  onClick={() => {
                    handleVCheck(`${check.role}`, "test", check.id);
                  }}
                  sx={{
                    backgroundColor: "white",
                    "&.Mui-selected:hover": {
                      backgroundColor: "#EBF4FF",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#EBF4FF",
                    },
                    "&.MuiTableRow-hover:hover": {
                      backgroundColor: "#EBF4FF",
                    },
                    cursor: "pointer",
                  }}
                >
                  <TableCell className={styles.tableCell} sx={{ width: "10px" }}>
                    <Checkbox
                      sx={{ zIndex: 1 }}
                      checked={isItemSelected}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleRowClick(event, formatSelectedRowId(check), check.amount as number);
                      }}
                    />
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Box className={styles["vChecksTable-btn-border"]}>
                      <Box className={styles.tableRow}>
                        {check.role === "recipient" ? (
                          <Box className={styles.recipient}>
                            <ArrowDownwardIcon className={styles.arrow} />
                            {highlightMatchingText(check.number!, filters?.search?.search_input!)}
                          </Box>
                        ) : (
                          <Box className={styles.sender}>
                            <ArrowUpwardIcon className={styles.arrow} />
                            {highlightMatchingText(check.number!, filters?.search?.search_input!)}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Typography fontWeight="500">
                      {formatDateToDesign(
                        (check as Record<string, any>)[filters?.date?.context ?? defaultDateContext],
                      ) ?? null}
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Box className={styles.info}>
                      <Typography
                        variant="body1"
                        className={styles.longTextEllipsis}
                        title={check.sender_title?.split("**")?.[0] || ""}
                      >
                        {highlightMatchingText(
                          check.sender_title?.split("**")?.[0] || "",
                          filters?.search?.search_input,
                        )}
                      </Typography>
                      <Typography className={styles.bank}>
                        {!!check.sender?.account_number &&
                          highlightMatchingText("**" + check.sender.account_number, filters?.search?.search_input)}
                      </Typography>
                      <Typography className={styles.bank}>
                        {highlightMatchingText(check?.sender_subtitle!, filters?.search?.search_input)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Box className={styles.info}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          position: "relative",
                          paddingRight: "20px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          className={styles.longTextEllipsis}
                          title={check?.recipient_title!}
                          sx={{
                            width: "auto !important",
                            maxWidth: "200px",
                          }}
                        >
                          {highlightMatchingText(check.recipient_title!, filters?.search?.search_input)}
                        </Typography>
                        {check.vendor_is_valid && (
                          <Tooltip arrow title="Verified Vendor!">
                            <VerifiedIcon
                              sx={{
                                fontSize: "14px",
                                color: COLORS.success,
                              }}
                            />
                          </Tooltip>
                        )}

                        <VCheckToChatDetails chatDetails={check.chat_details} />
                      </Box>
                      <Typography className={styles.bank}>
                        {highlightMatchingText(check.recipient_subtitle!, filters?.search?.search_input)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Typography textTransform="uppercase" fontWeight="500">
                      {formatCurrency(check.amount!)}
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Typography fontWeight="500">{capitalize(formatWords(check?.status?.status || ""))}</Typography>
                    {getAdditionalFields(check, matchPayments)}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {hasInProgressOnboarding ? null : check.action?.type === "action" ? (
                      <CheckAction
                        check={check}
                        reload={reload}
                        setDeselected={setDeselected}
                        selectedRowIds={selectedRowIds}
                        setSelectedRowIds={setSelectedRowIds}
                        handleAction={handleAction}
                      />
                    ) : (
                      <Typography>{check.action?.label}</Typography>
                    )}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <CheckOptions check={check} setDeselected={setDeselected} selectedRowIds={selectedRowIds} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default observer(VChecksTable);
