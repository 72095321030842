import { CHDecimal } from "common/helpers/decimal";
import { IVBillLineItemInput, IVBillNullableInvoiceInput } from "generated/sdk.vbill";
import { TVBillFormFields } from "../../types";
import { emptyOCRFormLineFields, formLineFieldsToLineItem } from "../../utils";

export const adaptIncomingVBillFormToInvoiceInputType = (
  formFields: TVBillFormFields,
  invoiceId: number,
): IVBillNullableInvoiceInput => {
  const {
    amount,
    invoiceDate,
    invoiceDateDue,
    invoiceNumber,
    senderName,
    senderEmail,
    senderAddress,
    senderPhone,
    mainFile,
    lineItems,
    hasLineItems,
  } = formFields;

  const lineItemsInput: IVBillLineItemInput[] = (lineItems ?? []).map((lineItem, index) => ({
    ...formLineFieldsToLineItem(lineItem),
    invoiceId,
    orderIndex: index,
  }));
  const invoiceAmount = new CHDecimal(amount.length ? amount : "0").toFixed(2);
  const emptyLineItem = {
    ...formLineFieldsToLineItem(emptyOCRFormLineFields),
    invoiceId,
    orderIndex: 0,
    amount: invoiceAmount ?? "0",
  };
  const invoiceData: IVBillNullableInvoiceInput = {
    amount: invoiceAmount,
    invoiceDate: invoiceDate?.length ? invoiceDate : null,
    invoiceDateDue: invoiceDateDue?.length ? invoiceDateDue : null,
    invoiceNumber: invoiceNumber.length ? invoiceNumber : null,
    senderName: senderName.length ? senderName : null,
    senderEmail: senderEmail.length ? senderEmail : null,
    senderAddress: senderAddress.length ? senderAddress : null,
    senderPhone: senderPhone.length ? senderPhone : null,
    mainFileId: mainFile?.value ? mainFile.value : null,
    lineItems: hasLineItems ? (lineItemsInput.length ? lineItemsInput : [emptyLineItem]) : [emptyLineItem],
    hasLineItems,
  };

  return invoiceData;
};

export const invoiceDateMoreThenFieldErrorMsg = "Bill Due should be more then Bill Date.";
