import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CreateIcon from "@mui/icons-material/Create";
import InfoIcon from "@mui/icons-material/Info";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Grid, Menu, MenuItem, Skeleton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { getCheckOtherContextDetails } from "common/helpers/getCheckOtherContextDetails";
import { useCheckPath } from "common/hooks/useCheckPath";
import { SHLink } from "components/common/SHLink/SHLink";
import { MoreHoriz } from "components/common/icons";
import { SelectBankAccount } from "components/pages/UndepositedChecks/Popups/SelectBankAccount";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { COLORS } from "themes/default";
import { JsonParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import { formatCurrency } from "../../../../common/helpers/utils";
import { useStore } from "../../../../storeContainer";
import ChecksStatusDialog from "../Dialogs/ChecksStatusDialog";
import ReviewAndConfirm from "../ReviewAndConfirm";
import { formatDateToDesign, formatWords, getAdditionalFields } from "../utils";
import AddCommentModal from "./AddCommentModal";
import CheckStubTable from "./CheckStubTable";
import EditVCheckModal from "./EditVCheckModal";
import RelatedTransactionsTable from "./RelatedTransactionsTable";
import styles from "./VCheckCheck.module.scss";
import {
  VCheckDetailsSidebar,
  vCheckSidebarPanelQSKey,
  vCheckSidebarPanels,
} from "./VCheckDetailsSidebar/VCheckDetailsSidebar";
import { VcheckTimeline } from "./VCheckTimeline";
import { IVCheckCheckProps } from "./types";
import { TabPanel, a11yProps } from "./utils";

const getColors = (status: string) => {
  const styleMap: Record<string, string> = {
    Signed: "#61B54B",
    Completed: "#61B54B",
    Processing: "#61B54B",
    Deposited: "#61B54B",
    "Sent to Recipient": "#697281",
    "Pending Signature": "#DB8C15",
    Cancelled: "#697281",
    Deleted: "#697281",
    Undeposited: "#697281",
  };
  return styleMap[status] || "#61B54B";
};

// const activityIcons: Record<number, JSX.Element> = {
//   0: <InvoiceIcon />,
//   1: <CommentIcon />,
//   2: <SavedIcon />,
//   3: <ApprovedIcon />,
//   4: <Info />,
// };
const VCheckCheck = ({
  check,
  modalClose,
  handleStartDepositCheck,
  handleStartCheckAction,
  isModal = false,
  hasNext,
  hasPrev,
  goNext,
  goPrev,
  isCheckBatch,
  handleReloadChecks,
  setSelectedRowIds,
  isModalOpened,
}: IVCheckCheckProps) => {
  const vChecksStore = useStore("VChecksStore");
  const AccountsStore = useStore("AccountsStore");
  const [availableBankAccounts, setAvailableBankAccounts] = useState<any>();
  const { acl } = useStore("SessionStore");
  const [isEditVCheckModalOpened, setIsEditVCheckModalOpened] = useState(false);
  const [openReviewAndConfirm, setOpenReviewAndConfirm] = useState<boolean>(false);
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<any>();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
  const path = useCheckPath(check);
  const [filters] = useQueryParam("filters", JsonParam);
  const [pagination] = useQueryParam("pagination", JsonParam);
  const { organizationId } = useParams<{ organizationId?: string }>();
  const [sidebarPanel] = useQueryParam<vCheckSidebarPanels>(
    vCheckSidebarPanelQSKey,
    withDefault<any, vCheckSidebarPanels>(StringParam, vCheckSidebarPanels.CHAT),
  );

  // const getActivityIcon = (activityType: number): JSX.Element => {
  //   return activityIcons[activityType] || <CommentIcon />; // Default to CommentIcon icon for unknown types
  // };

  const fetchBankAccounts = useCallback(async () => {
    try {
      await AccountsStore.getBankAccounts();
    } catch (error) {
      // Handle any errors thrown by AccountsStore.getBankAccounts()
      console.error("Error fetching bank accounts:", error);
      // Optionally, you can set a default value or clear the state if an error occurs
      setAvailableBankAccounts([]);
    }
  }, [AccountsStore]);

  const [isAction, setIsAction] = useState(true);
  const id = check?.id;
  const handleReload = useCallback(
    async (forceUpdate?: true | undefined) => {
      const baid = check?.role === "sender" ? check?.sender?.id : check?.recipient?.id;

      await vChecksStore.loadCheck(id, baid, forceUpdate);
      await vChecksStore.loadCheckRelatedTransactions(id, baid, forceUpdate);
    },
    [check?.recipient?.id, check?.role, check?.sender?.id, id, vChecksStore],
  );

  const handleAction = async (action: string, checks: [string]) => {
    if (action === "deposit" && handleStartDepositCheck) {
      handleStartDepositCheck(check);
    } else if (action === "deposit") {
      setOpenSelectBankAccountModal(true);
    } else if (handleStartCheckAction && isModal) {
      handleStartCheckAction(check, action, !isAction);
    } else {
      setOpenReviewAndConfirm(true);
    }
  };
  const [hasEditMemo, setHasEditMemo] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? "simple-popover" : undefined;
  const [checkDetailsTabValue, setCheckDetailsTabValue] = useState(0);
  const [sidebarTabValue, setSidebarTabValue] = useState(0);
  const [openSelectBanckAccountModal, setOpenSelectBankAccountModal] = useState<boolean>(false);
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);

  const handleCheckDetailsTabChange = (_event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setCheckDetailsTabValue(newValue);
  };

  const handleSideBarTabChange = (_event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setSidebarTabValue(newValue);
  };

  const [isAddCommentModalOpened, setIsAddCommentModalOpened] = useState(false);
  const openStatusDialog = () => {
    setIsStatusDialogOpen(true);
  };
  const handleAddCommentModalClose = () => {
    handleReload();
    setIsAddCommentModalOpened(false);
    setAnchorEl(null);
  };
  const handleEditVCheckModalClose = () => {
    setIsEditVCheckModalOpened(false);
    setAnchorEl(null);
  };
  const handleCloseDepositAll = () => {
    setOpenSelectBankAccountModal(false);
  };
  const checkDetails = vChecksStore.check.data;
  const checkRelatedTransactions = vChecksStore.checkRelatedTransactions;

  const canUpdateMemo = acl.canUpdateMemo(check?.sender ? check?.sender?.id : check?.recipient?.id!, check?.amount!);
  const canEditMemo =
    ["PENDING_SIGNATURE", "PENDING_REVIEW", "SIGNED"].includes(checkDetails?.ledger_check?.status?.state!) &&
    canUpdateMemo;
  const NoOptionsComponent = !canEditMemo ? (
    <Box className={styles.noAvailableOptions}>No available options</Box>
  ) : null;

  const otherContext = getCheckOtherContextDetails(checkDetails);
  const previewableExtensions = ["jpeg", "jpg", "png", "webp", "gif"];
  const ledgerCheckContext = checkDetails?.ledger_check?.role === "recipient" ? 1 : 0;

  const close = () => {
    setOpenReviewAndConfirm(false);
    modalClose?.();
  };

  useEffect(() => {
    if (id) {
      handleReload();
    }
  }, [handleReload, id]);

  useEffect(() => {
    if (!availableBankAccounts) {
      fetchBankAccounts();
    }
  }, [availableBankAccounts, fetchBankAccounts]);

  useEffect(() => {
    if (AccountsStore.depositAccounts) {
      setAvailableBankAccounts(AccountsStore.depositAccounts.DepositBankAccounts);
    }
  }, [AccountsStore.depositAccounts]);

  return (
    <Box width="100%" maxWidth="1400px" minWidth="300px">
      <ChecksStatusDialog isStatusDialogOpen={isStatusDialogOpen} setIsStatusDialogOpen={setIsStatusDialogOpen} />
      <Box className={styles.infoContainer} justifyContent={isModal ? "start" : "space-between"}>
        <Box className={styles.content} maxWidth={isModal ? "720px" : "100%"} margin={!isModal ? "0 auto" : "unset"}>
          {isModal && (
            <Box className={styles.header}>
              {vChecksStore.check.isFetching ? (
                <Skeleton variant="text" width={300} />
              ) : (
                <>
                  {hasPrev && goPrev && (
                    <ChevronLeftIcon onClick={() => goPrev()} sx={{ cursor: "pointer", fontSize: "24px" }} />
                  )}

                  {path && (
                    <Link to={path} className={styles.content} style={{ textDecoration: "none" }}>
                      Check {check?.number}
                    </Link>
                  )}
                  {hasNext && goNext && (
                    <ChevronRightIcon onClick={() => goNext()} sx={{ cursor: "pointer", fontSize: "24px" }} />
                  )}
                </>
              )}
            </Box>
          )}
          <Box className={styles.statusContainer}>
            <Grid container>
              {(vChecksStore.check.data?.ledger_check?.status?.state === "PENDING_SIGNATURE" ||
                vChecksStore.check.data?.ledger_check?.status?.state === "VOIDED") && (
                <>
                  <Box className={styles.labels}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "rgba(5, 32, 72, 0.5)",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "15px",
                      }}
                    >
                      Status
                    </Typography>
                    {vChecksStore.check.isFetching ? (
                      <Skeleton variant="text" />
                    ) : (
                      <div>
                        <Typography
                          variant="body1"
                          sx={{
                            color: `${getColors(checkDetails?.ledger_check?.status?.status!)}`,
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "17px",
                            marginTop: "4px",
                          }}
                        >
                          {capitalize(formatWords(checkDetails?.ledger_check?.status?.status || ""))}{" "}
                        </Typography>
                        {getAdditionalFields(checkDetails?.ledger_check)}{" "}
                        {vChecksStore.check.data?.ledger_check?.status?.state === "VOIDED" &&
                          formatDateToDesign(vChecksStore.check.data?.ledger_check.voided_at || "")}
                      </div>
                    )}
                  </Box>
                  <Box>
                    <IconButton onClick={openStatusDialog}>
                      <InfoIcon htmlColor="#2877EC" />
                    </IconButton>
                  </Box>
                </>
              )}
            </Grid>
            {otherContext && (
              <Box className={styles.labels}>
                <SHLink replace style={{ textDecoration: "none" }} to={otherContext.url}>
                  <Button sx={{ paddingY: 0.2, fontSize: "12px", width: "110px" }} variant="outlined">
                    View as the {otherContext.type === "sending" ? "sender" : "vendor"}
                  </Button>
                </SHLink>
              </Box>
            )}
            {vChecksStore.check.isFetching ? (
              <Skeleton variant="text" width={300} />
            ) : (
              <Box className={styles.actions}>
                {checkDetails?.ledger_check?.action && checkDetails?.ledger_check?.action?.type !== "label" && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={checkDetails?.ledger_check?.action?.disabled!}
                    sx={{ fontSize: "12px", padding: "10px 20px", marginRight: "20px" }}
                    onClick={() => {
                      setIsAction(true);
                      handleAction(checkDetails?.ledger_check?.action?.action || "", [id]);
                      handlePopoverClose();
                    }}
                  >
                    {checkDetails?.ledger_check?.action?.label}
                  </Button>
                )}

                <Menu
                  id={idPopover}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  {canEditMemo && (
                    <MenuItem
                      onClick={() => {
                        setIsEditVCheckModalOpened(true);
                        setHasEditMemo(true);
                      }}
                    >
                      Edit memo
                    </MenuItem>
                  )}
                  {canEditMemo && (
                    <MenuItem
                      onClick={() => {
                        setIsEditVCheckModalOpened(true);
                        setHasEditMemo(false);
                      }}
                    >
                      Edit pttoo
                    </MenuItem>
                  )}
                  {checkDetails?.ledger_check?.options
                    ? checkDetails?.ledger_check.options?.map((option, idx) => {
                        return (
                          <MenuItem
                            key={idx}
                            onClick={() => {
                              setIsAction(false);
                              handleAction(option?.action || "", [id]);
                              setOpenReviewAndConfirm(true);
                              handlePopoverClose();
                            }}
                          >
                            {option?.label}
                          </MenuItem>
                        );
                      })
                    : NoOptionsComponent}
                </Menu>
                <MoreHoriz
                  fontSize={"medium"}
                  sx={{ cursor: "pointer" }}
                  className={styles.icon}
                  onClick={handleClick}
                />
              </Box>
            )}
          </Box>
          <Box>
            {!vChecksStore.check.isFetching &&
              !!checkDetails?.timeline?.events?.length &&
              vChecksStore.check.data?.ledger_check?.status?.state !== "PENDING_SIGNATURE" &&
              vChecksStore.check.data?.ledger_check?.status?.state !== "VOIDED" && (
                <VcheckTimeline
                  isModalOpened={isModalOpened}
                  steps={checkDetails?.timeline?.events || []}
                  requiredActions={checkDetails?.ledger_check?.required_actions || []}
                  checkId={id}
                />
              )}
          </Box>
          <Box className={styles.detailsContainer}>
            <Box className={styles.row}>
              <Box className={styles.column}>
                <Typography className={styles.columnTextThin}>FROM</Typography>
                {vChecksStore.check.isFetching ? (
                  <>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </>
                ) : (
                  <>
                    <Typography className={styles.columnTextBold}>
                      {checkDetails?.ledger_check?.sender?.name}
                    </Typography>
                    <Typography className={styles.columnTextRegular}>
                      {checkDetails?.ledger_check?.sender?.bank_name}
                      {` **${checkDetails?.ledger_check?.sender?.account_number}`}
                    </Typography>
                  </>
                )}
              </Box>
              <Box className={styles.column}>
                <Typography align="right" className={styles.columnTextThin}>
                  DATE
                </Typography>
                {vChecksStore.check.isFetching ? (
                  <Skeleton variant="text" />
                ) : (
                  <Typography align="right" className={styles.columnTextBold}>
                    {checkDetails?.ledger_check?.date ? formatDateToDesign(checkDetails?.ledger_check.date) : null}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box className={styles.row}>
              <Box className={styles.column}>
                <Typography className={styles.columnTextThin}>PAY TO THE ORDER OF</Typography>
                {vChecksStore.check.isFetching ? (
                  <>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </>
                ) : (
                  <>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Typography className={styles.columnTextBold} title={checkDetails?.ledger_check?.pttoo ?? ""}>
                        {checkDetails?.ledger_check?.pttoo}
                      </Typography>

                      {checkDetails?.ledger_check?.vendor_is_valid && (
                        <Tooltip arrow title="Verified Vendor!">
                          <VerifiedIcon sx={{ fontSize: "20px", color: COLORS.success, marginRight: "30px" }} />
                        </Tooltip>
                      )}
                    </Box>
                    <Typography className={styles.columnTextRegular}>
                      {checkDetails?.ledger_check?.recipient_email}
                    </Typography>
                  </>
                )}
              </Box>
              <Box className={styles.column}>
                <Typography align="right" className={styles.columnTextThin}>
                  Amount:
                </Typography>
                {vChecksStore.check.isFetching ? (
                  <Skeleton variant="text" />
                ) : (
                  <Typography align="right" className={styles.columnTextBold}>
                    {formatCurrency(checkDetails?.ledger_check?.amount || 0)}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box className={styles.row}>
              <Box className={styles.column}>
                <Box className={styles.columnTextThin}>Memo</Box>
                {vChecksStore.check.isFetching ? (
                  <Skeleton variant="text" />
                ) : (
                  <Box className={styles.columnTextBold}>{checkDetails?.ledger_check?.memo}</Box>
                )}
              </Box>
            </Box>
          </Box>
          {vChecksStore.check.isFetching ? (
            <Skeleton variant="rectangular" width={660} height={200} sx={{ marginLeft: "30px" }} />
          ) : (
            <Box paddingX="60px">
              <Tabs
                value={checkDetailsTabValue}
                onChange={handleCheckDetailsTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                sx={{ marginBottom: "-2px" }}
                centered
                variant="fullWidth"
              >
                {!isCheckBatch && <Tab label="Check Stub" {...a11yProps(0)} sx={{ textTransform: "capitalize" }} />}
                <Tab label="Related transactions" {...a11yProps(1)} sx={{ textTransform: "capitalize" }} />
              </Tabs>
              <TabPanel value={checkDetailsTabValue} index={0}>
                {checkDetails && <CheckStubTable checkDetails={checkDetails} />}
              </TabPanel>
              <TabPanel value={checkDetailsTabValue} index={1}>
                {checkRelatedTransactions?.data?.related_transactions!.length! > 0 && (
                  <RelatedTransactionsTable
                    data={checkRelatedTransactions?.data?.related_transactions!}
                    isLoading={checkRelatedTransactions?.isFetching}
                  />
                )}
              </TabPanel>
            </Box>
          )}
        </Box>

        {!vChecksStore.check.isLoading && checkDetails && (
          <VCheckDetailsSidebar checkDetails={checkDetails} isModal={isModal} isModalOpened={isModalOpened} />
        )}

        {/* <Box className={styles.activityContainer}>
          <Tabs value={sidebarTabValue} onChange={handleSideBarTabChange} centered>
            <Tab
              label={
                <Box display="flex" alignItems="center" gap="8px">
                  <ChatIcon htmlColor="#16458B" />
                  <Typography variant="body1">Chat</Typography>
                </Box>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Box display="flex" alignItems="center" gap="8px">
                  <RestoreIcon htmlColor="#16458B" />
                  <Typography variant="body1">Activity log</Typography>
                </Box>
              }
              {...a11yProps(1)}
            />
          </Tabs>
          <TabPanel value={sidebarTabValue} index={0}>
            <Box paddingTop="45px" display="flex" justifyContent="center">
              <Typography variant="h2">This Feature is delayed to 11/18/2023.</Typography>
            </Box>
          </TabPanel>
          <TabPanel value={sidebarTabValue} index={1}>
            <ul className={styles.activityList}>
              {checkDetails?.activity!.map((activity, idx) => {
                return (
                  <li key={`${activity?.id}-${idx}`}>
                    {vChecksStore.check.isFetching ? (
                      <Skeleton variant="text" />
                    ) : (
                      <Box display="flex" gap="10px" alignItems="start" flexDirection="column">
                        <Box display="flex" gap="5px" alignItems="center">
                          <Typography className={styles.activityDate}>
                            {formatDateToDateTime(activity?.created_at!)}
                          </Typography>

                          {activity.username && (
                            <Typography variant="h1" fontWeight={400} fontSize="11px" lineHeight="13px" color="#697281">
                              {` - @${activity?.username}`}
                            </Typography>
                          )}
                        </Box>

                        {(activity?.title || activity?.note) && (
                          <Typography
                            className={classNames(styles.activityTitle, {
                              [styles.activityComment]: activity.type === 1,
                            })}
                          >
                            {activity?.title || activity?.note}
                          </Typography>
                        )}
                        {activity?.attachments?.at(0) &&
                        previewableExtensions.includes(activity.attachments.at(0)?.media?.type?.toLocaleLowerCase()!) &&
                        !activity.attachments.at(0)?.show_download ? (
                          <Box>
                            <img
                              className={styles.imageWrapper}
                              src={activity.attachments[0].media?.path!}
                              alt="activity attachement"
                            ></img>
                          </Box>
                        ) : (
                          activity.attachments?.at(0) && (
                            <Grid
                              alignItems={"center"}
                              justifyContent="space-between"
                              container
                              sx={{ paddingX: "35px" }}
                            >
                              <Typography fontSize={16}>{activity.attachments?.at(0)?.media?.filename}</Typography>
                              <a href={`/notes/${activity?.id}/media/${activity.attachments?.at(0)?.media?.id}`}>
                                <IconButton>
                                  <DownloadIcon color="secondary" />
                                </IconButton>
                              </a>
                            </Grid>
                          )
                        )}
                      </Box>
                    )}
                  </li>
                );
              })}
            </ul>
          </TabPanel>
        </Box> */}

        {checkDetails?.ledger_check?.status?.status !== "Voided" &&
          sidebarPanel === vCheckSidebarPanels.ACTIVITY_LOG && (
            <IconButton
              className={styles.iconButton}
              sx={{
                color: "white",
                backgroundColor: "primary.main",
                "&:hover": {
                  color: "white",
                  backgroundColor: "primary.main",
                },
                top: isModal ? "90%" : "65%",
              }}
              aria-label="Add comment"
              component="span"
              onClick={() => setIsAddCommentModalOpened(true)}
              disabled={vChecksStore.check.isFetching}
            >
              <CreateIcon />
            </IconButton>
          )}
      </Box>

      <AddCommentModal
        isModalOpened={isAddCommentModalOpened}
        checkId={id}
        modalClose={handleAddCommentModalClose}
        reload={handleReload}
        context={ledgerCheckContext}
      />
      <EditVCheckModal
        isModalOpened={isEditVCheckModalOpened}
        checkId={id}
        check={checkDetails?.ledger_check!}
        modalClose={handleEditVCheckModalClose}
        reload={handleReload}
        hasEditMemo={hasEditMemo}
      />
      <Modal
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={openReviewAndConfirm}
        onClose={async (e) => {
          close();
          if (id) {
            await vChecksStore.loadCheck(id, undefined, true);
          }
          if (handleReloadChecks) {
            handleReloadChecks();
          } else {
            await vChecksStore.loadChecks(organizationId, filters, pagination, true);
          }
        }}
      >
        <ReviewAndConfirm
          checksIds={[id]}
          checks={[check]}
          selectedRowIds={[id]}
          setSelectedRowIds={setSelectedRowIds}
          actionInfo={
            isAction
              ? {
                  action: vChecksStore?.check?.data?.ledger_check?.action?.action,
                  label: vChecksStore?.check?.data?.ledger_check?.action?.label,
                }
              : {
                  action: vChecksStore?.check?.data?.ledger_check?.options?.[0]?.action,
                  label: vChecksStore?.check?.data?.ledger_check?.options?.[0]?.label,
                }
          }
          handleReloadChecks={handleReloadChecks}
          close={close}
          selectedBankAccountId={selectedBankAccountId}
          selectedOrganizationId={selectedOrganizationId}
          isModal={isModal}
        />
      </Modal>
      {availableBankAccounts && (
        <SelectBankAccount
          allChecks={[check]}
          availableBankAccounts={availableBankAccounts}
          close={handleCloseDepositAll}
          openSelectBanckAccountModal={openSelectBanckAccountModal}
          selectedBankAccount={selectedBankAccountId}
          setOpenSelectBankAccountModal={setOpenSelectBankAccountModal}
          setSelectedBankAccountId={setSelectedBankAccountId}
          setOpenReviewAndConfirm={setOpenReviewAndConfirm}
          handleCloseDepositAll={handleCloseDepositAll}
          setSelectedOrganizationId={setSelectedOrganizationId}
        />
      )}
    </Box>
  );
};

export default observer(VCheckCheck);
