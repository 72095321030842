import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FixedSizeList } from "react-window";
import { SuggestionsSearchField } from "../common/SuggestionsSearchField/SuggestionsSearchField";
import styles from "./vBillSuggestionsSingleSelectAutocomplete.module.scss";
import { VirtualizedSuggestionLine } from "./VirtualizedSuggestionLine/VirtualizedSuggestionLine";

export interface IVBillSingleSelectAutocompleteSuggestion {
  id: string;
  name: string;
  label?: string | null;
}

export interface IVBillSuggestionsSingleSelectAutocompleteProps {
  title?: string;
  searchTerm?: string;
  searchPlaceholder: string;
  onSearchTermChange?: (value: string) => void;
  showSearchLoader?: boolean;
  onClose?: () => void;
  suggestions: IVBillSingleSelectAutocompleteSuggestion[];
  onSuggestionClick: (suggestion: IVBillSingleSelectAutocompleteSuggestion) => void;
  showMoreBtnVisible?: boolean;
  onShowMoreBtnClick?: () => void;
}

export const VBillSuggestionsSingleSelectAutocomplete = ({
  suggestions,
  onSuggestionClick,
  searchTerm,
  onSearchTermChange,
  title,
  searchPlaceholder,
  showSearchLoader,
  showMoreBtnVisible,
  onShowMoreBtnClick,
}: IVBillSuggestionsSingleSelectAutocompleteProps) => {
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm ?? "");
  const [localSuggestions, setLocalSuggestions] = useState<IVBillSingleSelectAutocompleteSuggestion[]>(suggestions);

  useEffect(() => {
    setLocalSearchTerm(searchTerm ?? "");
  }, [searchTerm]);

  useEffect(() => {
    if (localSearchTerm.length) {
      const currentSuggestions = suggestions.filter(({ name }) =>
        name.toLowerCase().startsWith(localSearchTerm.toLowerCase()),
      );

      setLocalSuggestions(currentSuggestions);
    } else {
      setLocalSuggestions(suggestions);
    }
  }, [localSearchTerm, suggestions]);

  const handleSearchTermChange = (value: string) => {
    setLocalSearchTerm(value);

    if (onSearchTermChange) {
      onSearchTermChange(value);
    }
  };

  return (
    <Box sx={{ padding: "10px" }}>
      {title && (
        <Typography noWrap sx={{ fontSize: "20px", margin: "0 10px 20px 0" }}>
          {title}
        </Typography>
      )}

      <SuggestionsSearchField
        showLoader={showSearchLoader}
        searchTerm={localSearchTerm}
        onSearchTermChange={handleSearchTermChange}
        placeholder={searchPlaceholder}
      />

      {!showSearchLoader && localSuggestions.length === 0 ? (
        <Box sx={{ width: "300px", height: "400px", paddingTop: "10px" }}>
          <Typography sx={{ textAlign: "center" }}>No results</Typography>
        </Box>
      ) : (
        <FixedSizeList
          height={400}
          itemCount={localSuggestions.length}
          itemSize={48}
          width={300}
          itemData={{
            suggestions: localSuggestions,
            searchTerm: localSearchTerm,
            onSuggestionClick,
          }}
          className={styles.fixedListContainer}
        >
          {VirtualizedSuggestionLine}
        </FixedSizeList>
      )}

      {showMoreBtnVisible && (
        <Button
          onClick={onShowMoreBtnClick}
          sx={{ padding: 0, height: "12px", "&:hover": { textDecoration: "underline" } }}
        >
          Show more
        </Button>
      )}
    </Box>
  );
};
