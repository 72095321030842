import { Box, Typography } from "@mui/material";
import { useCurrentVBillRoute } from "components/pages/VBill/hooks";
import { TVBillFormFields } from "components/pages/VBill/types";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

export const VBillToolbarBillIdInfo = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { isIncomingRoute } = useCurrentVBillRoute();
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const formInvoiceNumberObserver: TVBillFormFields["invoiceNumber"] = useWatch({ name: "invoiceNumber" });
  const invoiceNumberText = useMemo(
    () =>
      `Bill# ${isIncomingRoute ? (formInvoiceNumberObserver?.length ? formInvoiceNumberObserver : "-") : invoiceData?.invoiceNumber?.length ? invoiceData.invoiceNumber : "-"}`,
    [isIncomingRoute, formInvoiceNumberObserver, invoiceData?.invoiceNumber],
  );

  return (
    <Box>
      <Typography
        noWrap
        sx={{
          maxWidth: "280px",
          fontSize: "22px",
          lineHeight: "24px",
          fontWeight: "600",
          color: COLORS.logoBlue,
        }}
        title={invoiceNumberText}
      >
        {invoiceNumberText}
      </Typography>
      <Typography sx={{ fontSize: "12px", lineHeight: "12px", color: COLORS.textGray }}>
        cid: {invoiceData?.id}
      </Typography>
    </Box>
  );
});
