import {
  Box,
  FormControl,
  FormHelperText,
  InputProps,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { TVBillFormFields, TVBillFormLineFields } from "components/pages/VBill/types";
import { FunctionComponent, useMemo } from "react";
import { ControllerProps, useController } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { COLORS } from "themes/default";

interface IFormInputNumberProps {
  name: keyof TVBillFormFields | keyof TVBillFormLineFields;
  sx?: SxProps<Theme>;
  rules?: ControllerProps<TVBillFormFields>["rules"];
  insideForm?: boolean;
  priceField?: boolean;
  label?: string;
  pushedTop?: boolean;
  InputProps?: Partial<InputProps>;
  allowNegative?: boolean;
}

export const FormInputNumber: FunctionComponent<IFormInputNumberProps> = ({
  name,
  sx,
  rules,
  insideForm,
  priceField,
  label,
  pushedTop,
  InputProps,
  allowNegative,
}) => {
  const {
    field: { value, onChange: formFieldOnChange },
    fieldState: { error },
  } = useController<TVBillFormFields | TVBillFormLineFields>({ name, rules: rules as any });
  const staticRequiredField = useMemo(() => Object.keys(rules ?? {}).includes("required"), []);
  const outsideFormTextValue = useMemo(() => {
    if (!insideForm) {
      if (priceField) {
        return formatCurrency(Number(value ?? 0));
      }

      return `${value}`.length ? value : "-";
    }
  }, [value, insideForm, priceField]);

  return (
    <Box sx={{ ...sx, ...(pushedTop ? { marginBottom: "10px" } : {}) }}>
      {label && (
        <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>
          {label}
          {insideForm && staticRequiredField && (
            <Tooltip arrow title={rules?.["required"]}>
              <Box component="span" sx={{ fontSize: "16px", color: COLORS.error, marginLeft: "2px" }}>
                *
              </Box>
            </Tooltip>
          )}
        </Typography>
      )}

      {insideForm ? (
        <NumericFormat
          allowNegative={allowNegative}
          value={(value as string) ?? ""}
          prefix={priceField ? "$" : undefined}
          onValueChange={(values) => formFieldOnChange(values.value)}
          thousandSeparator={priceField}
          decimalScale={2}
          customInput={TextField}
          size="small"
          error={Boolean(error)}
          sx={{ width: "100%" }}
          InputProps={{ ...InputProps, sx: { height: "32px", ...InputProps?.sx } }}
        />
      ) : (
        <Typography sx={{ wordWrap: "break-word" }}>{outsideFormTextValue}</Typography>
      )}

      {error && error.type !== "required" && (
        <FormControl error fullWidth>
          <FormHelperText sx={{ margin: 0, lineHeight: "15px" }}>{error.message}</FormHelperText>
        </FormControl>
      )}
    </Box>
  );
};
