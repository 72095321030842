import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, TableRow, Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useVBillUrl } from "components/pages/common/VBill/hooks";
import {
  friendlyInvoiceIncomingStatusNames,
  friendlyInvoicePushStatusNames,
  getVBillFormattedDate,
} from "components/pages/common/VBill/utils";
import { highlightMatchingText } from "components/utils";
import { format } from "date-fns";
import { IVBillBillDateSortType, IVBillInvoiceIncomingStatus, IVBillVBillInvoicesQuery } from "generated/graphql.vbill";
import { observer } from "mobx-react";
import { CSSProperties, useMemo } from "react";
import { Link } from "react-router-dom";
import { useStore } from "storeContainer";
import { formatCurrency } from "../../../../common/helpers/utils";
import { COLORS } from "../../../../themes/default";
import { useCodingLedgerFilters, useVBillCodingLedgerChildRoute } from "../hooks";
import { VBillActions } from "./VBillActions/VBillActions";
import { VBillProcessingOverlay } from "./VBillProcessingOverlay/VBillProcessingOverlay";

export const defaultTableCellSx = {
  padding: "0",
};
const defaultLinkStyles: CSSProperties = {
  minHeight: "60px",
  textDecoration: "none",
  color: "inherit",
  padding: "5px 16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  fontSize: "14px",
  fontWeight: "500",
};

interface ICodingVBillProps {
  invoice: IVBillVBillInvoicesQuery["invoices"]["items"][number];
}

export const CodingVBill = observer(({ invoice }: ICodingVBillProps) => {
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");
  const [codingLedgerFilters] = useCodingLedgerFilters();
  const { isAllChildRoute, isCodingChildRoute, isCompletedChildRoute, isActionRequiredChildRoute } =
    useVBillCodingLedgerChildRoute();
  const vBillInvoiceUrl = useVBillUrl(invoice);
  const organizationStore = useStore("OrganizationStore");
  const vendorMappingKey = useMemo(
    () => vBillCodingLedgerStore.companiesSettings.data?.invoice?.find(({ isContact }) => isContact)?.key,
    [vBillCodingLedgerStore.companiesSettings.data?.invoice],
  );
  const companyName = useMemo(
    () =>
      (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).find(
        ({ companyId }) => companyId === invoice.companyId,
      )?.name,
    [invoice.companyId, organizationStore.organizationVBillEnabledCompanies.data?.items],
  );
  const billToName = companyName ? companyName : invoice.recipientName?.length ? invoice.recipientName : "-";
  const createDate = useMemo(() => getVBillFormattedDate(invoice.createdAt), [invoice.createdAt]);
  const billDueDate = useMemo(() => getVBillFormattedDate(invoice.invoiceDateDue), [invoice.invoiceDateDue]);
  const billDate = useMemo(() => getVBillFormattedDate(invoice.invoiceDate), [invoice.invoiceDate]);
  const emailDate = useMemo(
    () => (invoice.emailDate ? format(new Date(invoice.emailDate), "MM/dd/yy hh:mmaaa") : "-"),
    [invoice.emailDate],
  );
  const invoiceAmount = useMemo(() => formatCurrency(Number(invoice.amount)), [invoice.amount]);
  const invoiceStatusName = useMemo(() => friendlyInvoiceIncomingStatusNames[invoice.status], [invoice.status]);
  const invoiceSyncTooltipText = useMemo(
    () => (invoice.pushStatus ? friendlyInvoicePushStatusNames[invoice.pushStatus] : null),
    [invoice.pushStatus],
  );
  const filtersSearchTerm = useMemo(
    () => codingLedgerFilters.filters?.search ?? "",
    [codingLedgerFilters.filters?.search],
  );
  const vendorText = useMemo(() => {
    const vendorDetails = invoice.additionalMappings?.find((mapping) => mapping.key === vendorMappingKey);

    return vendorDetails ? `${vendorDetails.label ?? "-"} - ${vendorDetails.label2 ?? "-"}` : invoice.senderName;
  }, [invoice.additionalMappings, invoice.senderName, vendorMappingKey]);
  const datesSort = useMemo(() => codingLedgerFilters.filters?.dates_sort, [codingLedgerFilters.filters?.dates_sort]);
  const isCreateDateSort = useMemo(
    () => datesSort === IVBillBillDateSortType.CreateDateAsc || datesSort === IVBillBillDateSortType.CreateDateDesc,
    [datesSort],
  );
  const isBillDateSort = useMemo(
    () => datesSort === IVBillBillDateSortType.BillDateAsc || datesSort === IVBillBillDateSortType.BillDateDesc,
    [datesSort],
  );
  const isBillDueDateSort = useMemo(
    () => datesSort === IVBillBillDateSortType.BillDueAsc || datesSort === IVBillBillDateSortType.BillDueDesc,
    [datesSort],
  );

  return (
    <TableRow sx={{ cursor: "default", position: "relative" }}>
      <TableCell sx={defaultTableCellSx}>
        <VBillProcessingOverlay filesOcrState={invoice.filesOcrState} />

        <Box component={Link} to={vBillInvoiceUrl} sx={defaultLinkStyles}>
          <Box sx={{ maxWidth: "200px", wordBreak: "break-word" }}>
            <Tooltip arrow title={`Cherry Id: ${invoice.id}`}>
              <Box component="span" sx={{ color: COLORS.newSecondaryColor }}>
                #{highlightMatchingText(invoice.invoiceNumber ?? "-", filtersSearchTerm)}
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </TableCell>

      <TableCell sx={defaultTableCellSx}>
        <Box component={Link} to={vBillInvoiceUrl} sx={defaultLinkStyles}>
          <Box sx={{ maxWidth: "200px", wordBreak: "break-word" }}>
            {highlightMatchingText(vendorText ?? "", filtersSearchTerm)}
            <Tooltip
              title={
                <Box>
                  From: {invoice.initialSenderName ?? "-"}
                  <br /> Email: {invoice.initialSenderEmail ?? "-"} <br />
                  Subject: {invoice.emailSubject ? invoice.emailSubject : "-"} <br />
                  Date: {emailDate}
                </Box>
              }
              arrow
            >
              <ErrorOutlineIcon sx={{ fontSize: "12px", color: COLORS.mediumGrey, marginLeft: "5px" }} />
            </Tooltip>
          </Box>
        </Box>
      </TableCell>

      <TableCell sx={defaultTableCellSx}>
        <Box component={Link} to={vBillInvoiceUrl} sx={defaultLinkStyles}>
          <Box sx={{ maxWidth: "200px", wordBreak: "break-word" }}>{billToName}</Box>
        </Box>
      </TableCell>

      <TableCell sx={defaultTableCellSx}>
        <Box component={Link} to={vBillInvoiceUrl} sx={{ ...defaultLinkStyles, textAlign: "right" }}>
          {invoiceAmount}
        </Box>
      </TableCell>
      <TableCell sx={defaultTableCellSx}>
        <Box component={Link} to={vBillInvoiceUrl} sx={defaultLinkStyles}>
          <Box sx={{ width: "160px", wordBreak: "break-word" }}>
            <Box sx={{ ...(isCreateDateSort ? { fontWeight: "700" } : {}) }}>
              <Box component="span" sx={{ fontWeight: isCreateDateSort ? "700" : "400" }}>
                Create date:{" "}
              </Box>
              {createDate}
            </Box>
            <Box sx={{ ...(isBillDateSort ? { fontWeight: "700" } : {}) }}>
              <Box component="span" sx={{ fontWeight: isBillDateSort ? "700" : "400" }}>
                Bill date:{" "}
              </Box>
              {billDate}
            </Box>
            <Box sx={{ ...(isBillDueDateSort ? { fontWeight: "700" } : {}) }}>
              <Box component="span" sx={{ fontWeight: isBillDueDateSort ? "700" : "400" }}>
                Bill due:{" "}
              </Box>
              {billDueDate}
            </Box>
          </Box>
        </Box>
      </TableCell>

      {(isAllChildRoute || isCodingChildRoute || isCompletedChildRoute || isActionRequiredChildRoute) && (
        <TableCell sx={defaultTableCellSx}>
          <Box component={Link} to={vBillInvoiceUrl} sx={{ ...defaultLinkStyles }}>
            <Box sx={{ width: "80px", wordBreak: "break-word" }}>
              {invoice.status !== IVBillInvoiceIncomingStatus.Approved ? invoiceStatusName : invoiceSyncTooltipText}
            </Box>
          </Box>
        </TableCell>
      )}

      <VBillActions invoice={invoice} />
    </TableRow>
  );
});
