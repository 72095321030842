import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Divider, Skeleton, Tooltip, Typography } from "@mui/material";
import { IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn } from "generated/sdk.vbill";
import { partition } from "lodash";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { SelectAllMappingFieldSelector } from "./SelectAllMappingFieldSelector/SelectAllMappingFieldSelector";
import { SelectSingleMappingFieldSelector } from "./SelectSingleMappingFieldSelector/SelectSingleMappingFieldSelector";

export const NewSelectLineItemsMappings = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { invoice, settings } = vBillStore;
  const settingsData = useMemo(() => settings.data, [settings.data]);
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const [oneLineGlAccMapping, oneLineAdditionalMappings] = useMemo(() => {
    const lineAdditionalMappings = (settingsData?.vBill.additionalMappings.line ?? []).filter(
      (additionalMapping) =>
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
    );

    return partition(lineAdditionalMappings, (map) => map.isGlAccount);
  }, [settingsData?.vBill.additionalMappings.line]);
  const hasLineItems = useMemo(() => !!invoiceData?.hasLineItems, [invoiceData?.hasLineItems]);
  const showSelectMappingsSkeleton = useMemo(
    () => !!vBillStore.invoice.data?.companyId && vBillStore.settings.isLoading,
    [vBillStore.invoice.data?.companyId, vBillStore.settings.isLoading],
  );

  return (
    <>
      {showSelectMappingsSkeleton ? (
        <Skeleton variant="rounded" sx={{ height: "150px" }} />
      ) : (
        !!oneLineGlAccMapping.length &&
        !!oneLineAdditionalMappings.length && (
          <>
            <Divider sx={{ marginBottom: "10px" }} />

            <Typography
              sx={{
                fontSize: 16,
                marginBottom: "5px",
                fontWeight: 500,
                color: COLORS.logoBlue,
              }}
            >
              SELECT {hasLineItems ? "MAPPINGS" : "MAPPING"}{" "}
              <Tooltip arrow title={`Select ${hasLineItems ? "mappings for every item" : "mapping for item"}`}>
                <span>
                  <ErrorOutlineIcon sx={{ color: COLORS.textGray, fontSize: "14px" }} />
                </span>
              </Tooltip>
            </Typography>

            {oneLineGlAccMapping.map((additionalMapping) =>
              hasLineItems ? (
                <SelectAllMappingFieldSelector
                  invoiceCompanyId={invoiceData?.companyId ?? ""}
                  invoiceCoreOrgId={invoiceData?.coreOrgId ?? ""}
                  key={additionalMapping.storeDataType}
                  additionalMappings={additionalMapping}
                />
              ) : (
                <SelectSingleMappingFieldSelector
                  invoiceCompanyId={invoiceData?.companyId ?? ""}
                  invoiceCoreOrgId={invoiceData?.coreOrgId ?? ""}
                  key={additionalMapping.storeDataType}
                  additionalMappings={additionalMapping}
                />
              ),
            )}

            {oneLineAdditionalMappings.map((additionalMapping) =>
              hasLineItems ? (
                <SelectAllMappingFieldSelector
                  invoiceCompanyId={invoiceData?.companyId ?? ""}
                  invoiceCoreOrgId={invoiceData?.coreOrgId ?? ""}
                  key={additionalMapping.storeDataType}
                  additionalMappings={additionalMapping}
                />
              ) : (
                <SelectSingleMappingFieldSelector
                  invoiceCompanyId={invoiceData?.companyId ?? ""}
                  invoiceCoreOrgId={invoiceData?.coreOrgId ?? ""}
                  key={additionalMapping.storeDataType}
                  additionalMappings={additionalMapping}
                />
              ),
            )}
          </>
        )
      )}
    </>
  );
});
